import React from "react";
import styles from './styles.module.scss';
import scaleImage from "../../images/scaleImage.png";
import photo1 from "../../images/project1.jpg";
import photo2 from "../../images/project2.jpg";
import photo3 from "../../images/project3.jpg";


const Project = () => {
  return (
    <div className={styles.outerContainer}>
      <h1 className={styles.serviceContent} id="ourProject">Our Projects</h1>
      <section className={styles.mainContainer}>

        <section className={styles.container}>
          <img
            src={photo1}
            alt="scaleImage"
            className={styles.scaleImage}
          />
          <p className={styles.designTitle}>
            ARCHITECTURAL AND INTERIOR DESIGNS
          </p>
          
        </section>
      
        <section className={styles.container}>
          <img
            src={photo2}
            alt="scaleImage"
            className={styles.scaleImage}
          />
          <p className={styles.designTitle}>
            ARCHITECTURAL AND INTERIOR DESIGNS
          </p>
          
        </section>
      
        <section className={styles.container}>
          <img
            src={photo3}
            alt="scaleImage"
            className={styles.scaleImage}
          />
          <p className={styles.designTitle}>
            ARCHITECTURAL AND INTERIOR DESIGNS
          </p>
          
        </section>
      
      </section>
    
    </div>
  );
};

export default Project;
