import './team.scss'
import Roshani from '../../images/Ar. Roshani_Yadav.jpeg'
import Mukesh from '../../images/Er. Mukesh_Yadav.jpeg'
import Mahesh from '../../images/Er. Mahesh_Yadav.jpeg'
import Ashutosh from '../../images/Er. Ashutosh_Yadav.jpeg'
import Juli from '../../images/Accounts Juli_Yadav.jpeg'
import Anil from '../../images/Er. Anil Yadav.jpg'
import Rajesh from '../../images/Er. Rajesh Yadav.jpg'
import Ganesh from '../../images/JrEr Ganesh.jpg'
import Birendra from '../../images/Biren.jpg'


function Team() {
  return(
    <>
      <div className="team">
        <h1>Meet The Team</h1>
        <div className="members">

          <div className="center">
            <div className="member">
              <div className="member-head">
              <img src={Mahesh} alt="Er. Mahesh Yadav" />
              </div>

              <div className="member-name">
                <h4>Er. Mahesh Yadav</h4>
                <p>Founder & Strutural Engineer </p>
              </div>
            </div>
          </div>

          <div className="center">
            <div className="member">
              <div className="member-head">
              <img src={Juli} alt="Accountant Juli_Yadav" />
              </div>

              <div className="member-name">
                <h4>Juli Yadav</h4>
                <p>Accounts Head </p>
              </div>
            </div>
          </div>

         

          <div className="center">
            <div className="member">
              <div className="member-head">
              <img src={Birendra} alt="Birendra Kumar Yadav" />
              
              </div>

              <div className="member-name">
                <h4>Er. Birendra Kumar Yadav</h4>
                <p>Earthquake Engineer</p>
              </div>
            </div>
          </div>

          <div className="center">
            <div className="member">
              <div className="member-head">
                <img src={Ashutosh} alt = 'Er. Ashutosh Yadav'/>
              </div>

              <div className="member-name">
                <h4>Er. Ashutosh Yadav</h4>
                <p>Structural Engineer</p>
              </div>
            </div>
          </div>

      

          <div className="center">
            <div className="member">
              <div className="member-head">
                <img src={Roshani} alt='Ar. Roshani Yadav' />
              </div>

              <div className="member-name">
                <h4>Ar. Roshani Yadav</h4>
                <p>Architect</p>
              </div>
            </div>
          </div>


          <div className="center">
            <div className="member">
              <div className="member-head">
                <img src={Mukesh} alt= 'Er. Mukesh Yadav' />
              </div>

              <div className="member-name">
                <h4>Er. Mukesh Yadav</h4>
                <p>Civil Engineer</p>
              </div>
            </div>
          </div>

          <div className="center">
            <div className="member">
              <div className="member-head">
                <img src={Anil} alt= 'Er. Anil Yadav' />
              </div>

              <div className="member-name">
                <h4>Er. Anil Mandal</h4>
                <p>Civil Engineer</p>
              </div>
            </div>
          </div>

          <div className="center">
            <div className="member">
              <div className="member-head">
                <img src={Rajesh} alt= 'Er. Rajesh Yadav' />
              </div>

              <div className="member-name">
                <h4>Er. Rajesh Yadav</h4>
                <p>Civil Engineer</p>
              </div>
            </div>
          </div>

          

          <div className="center">
            <div className="member">
              <div className="member-head">
                <img src={Ganesh} alt='Jr Er Ganesh' />
              </div>

              <div className="member-name">
                <h4>Jr. Er. Ganesh Kumar Yadav</h4>
                <p>Site Supervisor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
