import { useState } from "react";
import { isEmail } from "validator";
import "./careers.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RingLoader } from "react-spinners";
import { Form } from "react-router-dom";

function Careers() {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState("");

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        position: "",
        addInfo: "",
    });

    const { firstName, lastName, email, position, addInfo } = values;

    const [errors, setErrors] = useState({
        lastName: "",
        firstName: "",
        email: "",
        position: "",
        addInfo: "",
        cv: "This field is required"
    });

    const inputHandler = (e) => {
        let val = e.target.value;
        setValues({ ...values, [e.target.name]: val });

        switch (e.target.name) {
            case "firstName":
                if (/\d/.test(val)) {
                    setErrors((errors) => ({ ...errors, firstName: "Name cannot contain numbers" }));
                } else if (!val) {
                    setErrors((errors) => ({
                        ...errors,
                        firstName: "First Name is required",
                    }));
                } else if (val.length < 3) {
                    setErrors((errors) => ({ ...errors, firstName: "First Name must be at least 3 characters" }));
                } else {
                    setErrors((errors) => ({ ...errors, firstName: "" }));
                }
                break;

            case "lastName":
                if (/\d/.test(val)) {
                    setErrors((errors) => ({ ...errors, lastName: "Last Name cannot contain numbers" }));
                } else if (!val) {
                    setErrors((errors) => ({ ...errors, lastName: "Last Name is required" }));
                } else if (val.length < 3) {
                    setErrors((errors) => ({ ...errors, lastName: "Last Name must be at least 3 characters" }));
                } else {
                    setErrors((errors) => ({ ...errors, lastName: "" }));
                }
                break;

            case "email":
                if (!val) {
                    setErrors((errors) => ({ ...errors, email: "Email is required" }));
                } else if (!isEmail(val)) {
                    setErrors((errors) => ({ ...errors, email: "Email is invalid" }));
                } else {
                    setErrors((errors) => ({ ...errors, email: "" }));
                }
                break;

            case "position":
                if (!val) {
                    setErrors((errors) => ({
                        ...errors,
                        position: "Position is required",
                    }));
                } else {
                    setErrors((errors) => ({ ...errors, position: "" }));
                }
                break;

            case 'file':
                let uploadedFileName = e.target.files[0].name;
                setFileName(uploadedFileName);
                setErrors((errors) => ({ ...errors, cv: "" }));
                break;
            default:
                break;
        }
    };

    const submit = (e) => {
        e.preventDefault();

        // Check if any fields are blank
        const hasBlankFields = Object.values(values).some((value) => value === "");
        if (hasBlankFields) {
            alert("Please fill in all the fields");
            return;
        }
        
        // Check if any errors exist in the errors state
        const hasErrors = Object.values(errors).some((error) => error !== "");
        if (hasErrors) {
            alert("Please fix the errors in the form");
            return;
        }


        setLoading(true);

        axios
          .post("https://node-metropolitandesign.onrender.com/careers", values)
          .then((res) => {
            toast(res.data.message);
            setFormSubmitted(true);
            toast(res.data.message);
          })
          .catch((err) => {
            console.log(err);
            toast("Error Sending Data, Please Try Again");
          }).finally(()=>{
            setLoading(false);
          });
        

        if (formSubmitted) {
            return <>
                <h1> Form submitted Sucessfully</h1>
            </>
        };
}

    return (
        <>
            <form
                className="form-box"
                encType="multipart/form-data"
                onSubmit={submit}
            >
                <h1>Drop your CV</h1>
                <p>
                    Do you want to work with us? Please fill in your details below, we
                    will get back to you
                </p>
                <br />
                <br />
                <hr />

                <div className="form-items">
                    <p>First Name</p>
                    <div className="input_error">
                        <input onChange={inputHandler} value={firstName} name="firstName" />
                        {errors.firstName && ( <div className="error">{errors.firstName}</div> )}
                    </div>
                </div>

                <div className="form-items">
                    <p>Last Name</p>
                    <div className="input_error">
                        <input type='text' onChange={inputHandler} value={lastName} name="lastName" />
                        {errors.lastName && <div className="error">{errors.lastName}</div>}
                    </div>
                </div>

                <div className="form-items">
                    <p>Email Address</p>
                    <div className="input_error">
                        <input type='text' onChange={inputHandler} value={email} name="email" />
                        {errors.email && <div className="error">{errors.email}</div>}
                    </div>
                </div>

                <div className="form-items">
                    <p>Position Applying For</p>
                    <div className="input_error">
                        <input type='text' onChange={inputHandler} value={position} name="position" />
                        {errors.position && <div className="error">{errors.position}</div>}
                    </div>
                </div>

                <div className="form-items">
                    <p>Additional Information</p>
                    <textarea type='text' placeholder='' name="addInfo"  onChange={inputHandler} value= {addInfo}/>
                </div>

                <div className="form-items">
                    <p>Upload your CV Here</p>
                    <div className="cv-input">
                        <input
                            id="file-input"
                            onChange={inputHandler}
                            type="file"
                            name="file"
                        />
                        <label htmlFor="file-input">
                            <svg
                                width="151"
                                height="156"
                                viewBox="0 0 151 156"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M121.618 65.5508C117.344 43.1977 98.3002 26.4167 75.4221 26.4167C57.258 26.4167 41.4822 37.0425 33.6257 52.5925C14.7073 54.6658 0 71.1877 0 91.2083C0 112.654 16.9071 130.083 37.7111 130.083H119.418C136.765 130.083 150.844 115.57 150.844 97.6875C150.844 80.5825 137.96 66.7171 121.618 65.5508ZM87.9925 84.7292V110.646H62.8518V84.7292H43.9963L75.4221 52.3333L106.848 84.7292H87.9925Z"
                                    fill="#414040"
                                />
                            </svg>

                            <p>Browse files</p>
                            <p>Drag and drop files here</p>
                        </label>
                        <p id="file-name">{fileName}</p>
                        {errors.cv && <div className="error">{errors.cv}</div>}

                    </div>
                </div>

                <button type="submit" 
                disabled={loading}
                >
                    {loading ? <RingLoader color="#fff" size={16} /> : "Submit"}
                </button>
            </form>
        </>
    );
}
export default Careers;
