import './Connect.scss';
import React, {  useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmail } from 'validator';
import { RingLoader } from 'react-spinners';

export default function Connect() {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        name: '',
        phone: '',
        email: '',
        your_query: '',
        whence: ''
      });
    
    const { name, phone, email, your_query, whence} = values;

    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        email: '',
        your_query: '',
        whence: ''
      });
      

    const inputHandler = (e) => {
        let val = e.target.value;
        setValues({ ...values, [e.target.name]: val });
      
        // Validate the input and set the error message in the errors state
        switch (e.target.name) {
          case 'name':
            if (!val) {
              setErrors((errors) => ({ ...errors, name: 'Name is required' }));
            } else if (val.length < 3) {
              setErrors((errors) => ({ ...errors, name: 'Name must be at least 3 characters' }));
            } 
            else if (/\d/.test(val)) {
                setErrors((errors) => ({ ...errors, name: 'Name cannot contain numbers' }));
              } else {
                setErrors((errors) => ({ ...errors, name: '' }));
              }
            break;
          case 'phone':
            if (!val) {
              setErrors((errors) => ({ ...errors, phone: 'Phone is required' }));
            } else if (!/^\d{10}$/.test(val)) {
              setErrors((errors) => ({ ...errors, phone: 'Phone must be a 10-digit number' }));
            } else {
              setErrors((errors) => ({ ...errors, phone: '' }));
            }
            break;
          case 'email':
            if (!val) {
              setErrors((errors) => ({ ...errors, email: 'Email is required' }));
            } else if (!isEmail(val)) {
              setErrors((errors) => ({ ...errors, email: 'Email is invalid' }));
            } else {
              setErrors((errors) => ({ ...errors, email: '' }));
            }
            break;
          case 'your_query':
            if (!val) {
              setErrors((errors) => ({ ...errors, your_query: 'Query is required' }));
            } else if (val.length < 6) {
              setErrors((errors) => ({ ...errors, your_query: 'Query must be at least 6 characters' }));
            } else {
              setErrors((errors) => ({ ...errors, your_query: '' }));
            }
            break;
          default:
            break;
        }
      };
      
 
  const submit = (e) => {
    e.preventDefault();
        
    console.log(values);
    // Check if any fields are blank
    const hasBlankFields = Object.values(values).some((value) => value === '');
    if (hasBlankFields) {
      alert('Please fill in all the fields');
      return;
    }

        // Check if any errors exist in the errors state
        const hasErrors = Object.values(errors).some((error) => error !== '');
        if (hasErrors) {
          alert('Please fix the errors in the form');
          return;
        }

        setLoading(true);
      
        // axios
        //   .post("https://node-metropolitandesign.onrender.com/query", values)
        //   .then((res) => {
        //     toast(res.data.message);
        //     setFormSubmitted(true);
        //     toast(res.data.message);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     toast("Error Sending Data, Please Try Again");
        //   }).finally(()=>{
        //     setLoading(false);
        //   });
    };
      
  
    
    if(formSubmitted) {
      return <>
      <h1> Form submitted Sucessfully</h1>
      </>
    }
        return (
            <>
                <section className='connect_box' onSubmit={submit}>

                    <h2>Connect with Us</h2>
    
                    <form className='form_box'>
                        <h3>Fill in your details</h3>
                        <div className='fieldset'>
                            <label htmlFor='text1'>Full name<span>*</span></label>
                            <div className='input_error'>
                              <input type='text' id='text1' name="name" placeholder='' onChange={inputHandler} value= {name}/>
                              {errors.name && <div className='error'>{errors.name}</div>}
                            </div>  
                        </div>
                        
                        <div className='fieldset'>
                            <label htmlFor='text1'>Phone<span>*</span></label>
                            <div className='input_error'>
                              <input type='text' id='text1' name="phone"  placeholder='' onChange={inputHandler} value= {phone}/>
                              {errors.phone && <div className='error'>{errors.phone}</div>}
                            </div>
                        </div>
    
                        <div className='fieldset'>
                            <label htmlFor='text1'>Email<span>*</span></label>
                            <div className='input_error'>
                              <input type='text' id='text1' name="email"  placeholder='' onChange={inputHandler} value= {email}/>
                              {errors.email && <div className='error'>{errors.email}</div>}
                            </div> 

                        </div>
    
                        <div className='fieldset'>
                            <label htmlFor='text1'>Your Query<span>*</span></label>
                            <div className='input_error'>
                              <textarea type='text' id='text1' name="your_query"  placeholder='' onChange={inputHandler} value= {your_query}/>
                              {errors.your_query && <div className='error'>{errors.your_query}</div>}
                            </div>
                        </div>
    
                        <div className='fieldset'>
                            <label htmlFor='text1'>How did you hear about us?</label>
                            <input type='text' id='text1' placeholder='' name="whence"  onChange={inputHandler} value= {whence}/>
                            
                        </div>
    
                        <button type="submit" disabled={loading}>
                          {loading ? (<RingLoader color="#fff" size={16} />) : ('Submit')}
                        </button>
                    </form>
                </section>
            <ToastContainer />
    
            </>
        )
    
    
} 