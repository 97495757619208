import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import LandingPage from "./Components/Landing Page/Landing_page";
import Footer from "./Components/Footer"
import Careers from "./Pages/Careers";

function App() {
  return (
    <div>
      <Router>
        <Navbar />

        <Routes>
          <Route  path='/' element={< LandingPage />}></Route>
          <Route  path='/careers' element={< Careers />}></Route>
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
