import React from "react";
import styles from "./styles.module.scss";
import scaleImage from "../../images/scaleImage.png";
import structural_design from "../../images/structural_design.png"
import architectural_design from "../../images/architectural_design.png"
import interior from "../../images/interior.png"
import construction from "../../images/construction.png"
import renovations from "../../images/renovations.png"
import repair from "../../images/repair.png"
import project_management from "../../images/project_management.png"
const Service = () => {
  return (
    <div className={styles.outerContainer}>
      <h1 className={styles.serviceContent} id="ourService">Our Services</h1>
      <section className={styles.mainContainer}>
        <section className={styles.container}>
          <img
            src={structural_design}
            alt="structural_design"
            className={styles.scaleImage}
          />
          <h2 className={styles.designTitle}>STRUCTURAL DESIGN</h2>
          <p className={styles.designDescription}>
            "We offer a range of design services, including
            Structural,architecture, interior design, and landscape design. Our
            team will work with you to create a cohesive and functional space
            that reflects your style and meets your need. Our in-house design
            team can help you create a unique, personalized space that reflects
            your style."
          </p>
        </section>

        <section className={styles.container}>
          <img
            src={architectural_design}
            alt="architectural_design"
            className={styles.scaleImage}
          />
          <h2 className={styles.designTitle}>ARCHITECTURAL DESIGNS</h2>
          <p className={styles.designDescription}>
            "Our team of architects can design the overall structure and layout
            of your building, ensuring that it meets your needs and reflects
            your personal style. We offer a range of design services, including
            custom plans, 3D renderings, and more."
          </p>
        </section>

        <section className={styles.container}>
          <img
            src={interior}
            alt="interior_design"
            className={styles.interior}
          />
          <h2 className={styles.designTitle}>INTERIOR DESIGNS</h2>
          <p className={styles.designDescription}>
            "Our team of interior designers is skilled in creating custom plans
            and layouts that meet your needs and budget. We can help you select
            the perfect furniture, colors, and finishes to transform your space
            into a welcoming and functional environment."
          </p>
        </section>

        <section className={styles.container}>
          <img
            src={construction}
            alt="construction"
            className={styles.scaleImage}
          />
          <h2 className={styles.designTitle}>CONSTRUCTION</h2>
          <p className={styles.designDescription}>
            "From start to finish, we handle all aspects of construction,
            including site preparation, framing, electrical, plumbing, and more.
            We use only the highest-quality materials and adhere to strict
            safety standards to ensure the best possible results"
          </p>
        </section>
        {/* completed */}
        <section className={styles.container}>
          <img
            src={renovations}
            alt="renovations"
            className={styles.scaleImage}
          />
          <h2 className={styles.designTitle}>RENOVATIONS AND REMODELING</h2>
          <p className={styles.designDescription}>
            "Whether you want to update an existing space or add on to your home
            or business, we can help you create a functional, attractive space
            that meets your needs. Or if you're looking to update or improve
            your existing home or business, we can help. From adding a new room
            to updating the kitchen or bathroom, we can handle projects of all
            sizes and types."
          </p>
        </section>

        <section className={styles.container}>
          <img
            src={repair}
            alt="Maintenance and repairs"
            className={styles.scaleImage}
          />
          <h2 className={styles.designTitle}>MAINTENANCE AND REPAIRS</h2>
          <p className={styles.designDescription}>
            "We offer regular maintenance and repair services to keep your home
            or business in top condition. Whether you need a simple repair or a
            more extensive overhaul, we have the skills and expertise to get the
            job done."
          </p>
        </section>
        <section className={styles.container}>
          <img
            src={project_management}
            alt="project_management"
            className={styles.scaleImage}
          />
          <h2 className={styles.designTitle}>PROJECT MANAGEMENT</h2>
          <p className={styles.designDescription}>
            "Our project managers are skilled in coordinating all aspects of your project, from scheduling and budgeting to subcontractor management and quality control. We strive to make the construction process as smooth and stress-free as possible for our Clients. We provide expert guidance and oversight to ensure your project stays on track and on budget."
          </p>
        </section>
      </section>
    </div>
  );
};

export default Service;
