import './Landing_page.scss';
import Cars from '../../images/hero.jpg'
import Service from "../Service";
import Project from "../Project";
import ConnectWithUs from "../ConnectWithUs";
import Team from '../Team';
import Marquee from "react-fast-marquee";

export default function LandinPage() {
    return (
        <>
            <section className='landing_page'>
                <div className='title'>
                   <Marquee gradientColor={[, 44, 1]} gradientWidth={400} pauseOnHover= {true} speed={100}><h1>Welcome to Metropolitan Design Pvt. Ltd </h1></Marquee> 
                    
                    {/* <img src={Cars} alt="Car parking" /> */}
                </div>

                <h2>We bring concept to reality</h2>



                <img src={Cars} alt="Car parking" id='whyChooseUs'/>
                <h3  >Why Choose Us?</h3>
                <p className='description' >"We are a full-service construction and design firm that specializes in residential and commercial projects. Our team of skilled professionals has years of experience in the industry and a passion for delivering high-quality results."</p>
                <p className='description'>"At Metropolitan Design Pvt. Ltd, we believe in the power of collaboration. We work closely with our clients to understand their vision and bring it to life through innovative design solutions and top-notch construction services."</p>
                <p className='description'>"Whether you are building a new home or renovating an existing space, we have the expertise to make your project a success. We use the latest technology and techniques to ensure that your project is completed efficiently and to the highest standards and have a strong track record of completing projects on time and within budget. We have a customer-focused approach and work closely with our clients to ensure that their vision for their project becomes a reality With strong commitment to sustainability and use eco-friendly materials and methods whenever possible."</p>
                {/* <p className='description'>""</p>
                <p className='description'>""</p> */}

                <Service />
                <Project />
                <Team/>
                <ConnectWithUs />         

            </section>
        </>
    )
}

