import "./Footer.scss";
import facebook from "../../images/facebookLogo.png";
import twitter from "../../images/twitterLogo.png";
import instagram from "../../images/instagramLogo.png";

export default function () {
  return (
    <>
      <section className="footer_box">
        <div className="company_info">
          <p>
            "Thank you for considering Metropolitan design Pvt. Ltd for your
            next project. we pride ourselves on our attention to detail and
            commitment to customer satisfaction. Our team is here to help you
            every step of the way, from initial planning to final touches.
            Contact us today to schedule a consultation and see how we can help
            you bring your construction and design dreams to life. We look
            forward to working with you to bring your vision to life!"
          </p>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1781.7173230020544!2d85.92955775593943!3d26.73050257434608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ec3faec48c7975%3A0xf01f6077ac2c64bd!2sMetropolitan%20Design!5e0!3m2!1sen!2snp!4v1671957687738!5m2!1sen!2snp"
          width="80%"
          height="500"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

        <div className="company_details">
          <div className="about">
            {/* <h4>About Us</h4> */}
            <ul>
              <a href="/">
                <li>About Us</li>
              </a>
              <a href="/">
                <li>Careers</li>
              </a>
              <a href="">
                <li>Privacy Policies</li>
              </a>
              <a href="">
                <li>Terms of Services</li>
              </a>
              <a href="">
                <li>FAQ</li>
              </a>
            </ul>
          </div>

          <div className="follow">
            <p>Follow Us</p>
            <ul>
              <a href="https://www.facebook.com/metropolitandesign20" target="_blank">
                <li>
                  <img src={facebook} alt="facebook logo" />
                </li>
              </a>
              <a href="https://twitter.com/" target="_blank">
                <li>
                  <img src={twitter} alt="twitter logo" />
                </li>
              </a>
              <a href="https://www.instagram.com/" target="_blank">
                <li>
                <img src={instagram} alt="instagram" />
                </li>
              </a>
            </ul>
          </div>

          <div className="contact">
            <h4 id="contact">Contact </h4>
            <a href="tel:041590989">041-590989</a>
            <a href="tel:+9779862005577">+9779862005577</a>
           
            <p>
              Metropolitan Design Pvt. Ltd., <p>Janakpurdham, Nepal</p>
            </p>
            <a href="mailto:info@metropolitandesign.com.np">
              info@metropolitandesign.com.np
            </a>
          </div>
        </div>

        <div className="copy_right">
          <p>© Copyrights 2022 - Metropolitan Design Pvt. Ltd.</p>
        </div>
      </section>
    </>
  );
}
