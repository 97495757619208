import React from 'react';
import './Navbar.scss';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { useState } from 'react';
import logo from '../../images/logo.png'
import Dropdown from '../Dropdown/Dropdown'
import DropSvg from '../../svg/Down'

function Navbar() {

  const [show, setShow] = useState(false);
  // const [dropDown, setDropdown] = useState(false);

  function toggle() {
    setShow(!show);
  }

  return (
    <>
      <nav>
        <Link to='/'><img src={logo} alt="logo" /></Link>

        
          <ul className={show ? 'sidebar_active' : 'sidebar'}>

            <li ><Link to="/" onClick={()=>{setShow(false)}}>Home</Link></li>
            {/* <li onMouseEnter={() => setDropdown(!dropDown)}
              onMouseLeave={() => setDropdown(!dropDown)}>
              <a href="#ourProject"><span className="projects_dropdown"><DropSvg /></span> Projects</a>

              {dropDown && <Dropdown drop={dropDown} dropSet={setDropdown} />}

            </li> */}

            <li>
             <a href="#ourProject">Projects</a>
            </li>
            <li ><a href="/#ourService" onClick={()=>{setShow(false)}}>Our Services</a></li>
            <li ><a href="/#whyChooseUs" onClick={()=>{setShow(false)}}>About us</a></li>
            <li ><Link to="/careers" onClick={()=>{setShow(false)}}>Careers</Link></li>
            <li ><a href="/#contact" onClick={()=>{setShow(false)}}>Contact</a></li>
          </ul>
          <div onClick={toggle} className={show? 'close_nav_active' : 'close_nav'}></div>

        <svg className='menu' onClick={toggle} width="96" height="96" viewBox="0 0 96 96" fill="grey" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_6_2)">
            <path d="M12 72H84V64H12V72ZM12 52H84V44H12V52ZM12 24V32H84V24H12Z" fill="grey" />
          </g>
          <defs>
            <clipPath id="clip0_6_2">
              <rect width="96" height="96" fill="grey" />
            </clipPath>
          </defs>
        </svg>

      </nav>
    </>
  )
}

export default Navbar;